import Api from '@/api/Api'

export default {
  login(param) {
    return Api().post('/auth/login', param)
  },

  logout(param) {
    return Api().post('/auth/logout', param)
  },
  
  getAuth(headers) {
    return Api().get('token/get_auth', {headers})
  },

  getMarketplace(params) {
    return Api().get(`token/get_marketplace?merchant=${params}`)
  },

  getLogo(data) {
    return Api().post('/auth/get_logo', data)
  },
}
