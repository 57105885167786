import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/transform`)
  },

  create(data) {
    return Api().post(`/transform`, data)
  },

  update(id, data) {
    return Api().put(`/transform?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/transform?_id=${id}`)
  },

  getField() {
    return Api().get(`/transform/add`)
  },

  getDetail(id) {
    return Api().get(`/transform/detail?_id=${id}`)
  },
 

}