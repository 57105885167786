import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/news-event-detail`)
  },

  create(data) {
    return Api().post(`/news-event-detail`, data)
  },

  update(id, data) {
    return Api().put(`/news-event-detail?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/news-event-detail?_id=${id}`)
  },

  getField() {
    return Api().get(`/news-event-detail/add`)
  },

  getDetail(id) {
    return Api().get(`/news-event-detail/detail?_id=${id}`)
  },
 

}