export default {
  //LOCAL
  // url_server: 'http://localhost:1001'

  // PRODUCTION
  url_server: 'https://api.transaviaotomasi.com'

  // TESTING
  // url_server: 'http://34.101.231.179/indobudget2021'
}
