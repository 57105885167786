import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/user`)
  },

  create(data) {
    return Api().post(`/user`, data)
  },

  update(id, data) {
    return Api().put(`/user?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/user?_id=${id}`)
  },

  getField() {
    return Api().get(`/user/add`)
  },

  getDetail(id) {
    return Api().get(`/user/detail?_id=${id}`)
  },

  /* get(headers, params) {
    let content = {
      headers,
      params
    }
    return Api().get(`/api/user`, content)
  },

  getField(params) {
    return Api().get(`api/user/get_field`, {params})
  },


  CreateData(data, headers) {
    return Api().post(`api/user/`, data, headers)
  },

  getDataFromClient(data) {
    return Api().post(`api/user/get_data_from_client`, data)
  },
  
  UpdateData(data, headers, _id) {
    return Api().post(`api/user/update_data?_id=${_id}`, data, headers)
  },

  deleteItem(headers, data) {
    return Api().delete(`api/user/delete_item?_id=${data}`, { headers })
  }, */
}
