import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/contact`)
  },

  create(data) {
    return Api().post(`/contact`, data)
  },

  update(id, data) {
    return Api().put(`/contact?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/contact?_id=${id}`)
  },

  getField() {
    return Api().get(`/contact/add`)
  },

  getDetail(id) {
    return Api().get(`/contact/detail?_id=${id}`)
  },
 

}