import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/product`)
  },

  create(data) {
    return Api().post(`/product`, data)
  },

  update(id, data) {
    return Api().put(`/product?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/product?_id=${id}`)
  },

  getField() {
    return Api().get(`/product/add`)
  },

  getDetail(id) {
    return Api().get(`/product/detail?_id=${id}`)
  },
  /* get(headers, params) {
    let content = {
      headers,
      params
    }
    return Api().get(`/api/product`, content)
  },
  
  getProductFromClient(data) {
    return Api().post(`api/product/get_product_from_client`, data)
  },
  
  getDataFromClient(data) {
    return Api().post(`api/product/get_product_from_client`, data)
  },

  push(headers, data) {
    return Api().post(`api/product/`, data, {headers})
  },

  updateCategoryId(data) {
    return Api().post(`api/product/update_category_id`, data)
  },

  UpdateData(data, headers, _id) {
    return Api().post(`api/product/update_data?_id=${_id}`, data, headers)
  },

  deleteItem(headers, data) {
    return Api().delete(`api/product/delete_item?_id=${data}`, { headers })
  }, */
  /* getVendor(id) {
    return Api().get(`product/?vendor_id=${id}`)
  },
  getDetail(id) {
    return Api().get(`/product/${id}`)
  },
  Update(id, data) {
    return Api().put(`/product/${id}`, data)
  },
  AddProduct(data) {
    return Api().post(`/product/`, data)
  },
  DeleteProduct(id) {
    return Api().delete(`/product/${id}`)

  },
  uploadImage(data, id) {
    return Api().post(`product/upload/${id}`, data)

  } */
}
