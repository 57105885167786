import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/case-study`)
  },

  create(data) {
    return Api().post(`/case-study`, data)
  },

  update(id, data) {
    return Api().put(`/case-study?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/case-study?_id=${id}`)
  },

  getField() {
    return Api().get(`/case-study/add`)
  },

  getDetail(id) {
    return Api().get(`/case-study/detail?_id=${id}`)
  },
 

}