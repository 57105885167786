import Api from '@/api/Api'

export default {
  get() {
    return Api().get(`/home`)
  },

  create(data) {
    return Api().post(`/home`, data)
  },

  update(id, data) {
    return Api().put(`/home?_id=${id}`, data)
  },

  delete(id) {
    return Api().delete(`/home?_id=${id}`)
  },

  getField() {
    return Api().get(`/home/add`)
  },

  getDetail(id) {
    return Api().get(`/home/detail?_id=${id}`)
  },
 

}